import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const constantRouterMap = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/404",
    component: () => import("@/components/404"),
    hidden: true,
  },
  {
    path: "/index",
    component: () => import("@/components/tabbar"),
    redirect: "/index",
    children: [
      {
        path: "/index",
        component: () => import("@/views/home"),
      },
    ],
  },
  {
    path: "/order",
    component: () => import("@/components/tabbar"),
    hidden: true,
    children: [
      {
        path: "/order/index",
        component: () => import("@/views/order/index"),
      },
    ],
  },
  {
    path: "/goods",
    component: () => import("@/components/tabbar"),
    hidden: true,
    children: [
      {
        path: "/goods/index",
        component: () => import("@/views/goods/index"),
      },
      {
        path: "/goods/addInfo",
        component: () => import("@/views/goods/goodsAddInfo"),
      },
      {
        path: "/goods/goodsInfo",
        component: () => import("@/views/goods/goodsInfo"),
      },
    ],
  },
  {
    path: "/shop",
    component: () => import("@/components/tabbar"),
    hidden: true,
    children: [
      {
        path: "/shop/index",
        component: () => import("@/views/shop/index"),
      },
    ],
  },
  {
    path: "/store",
    component: () => import("@/components/tabbar"),
    hidden: true,
    children: [
      {
        path: "/store/index",
        component: () => import("@/views/store/index"),
      },
    ],
  },
  {
    path: "/components/videoWindows",
    hidden: true,
    component: () => import("@/components/ImouVideo/videoWindows"),
  },
  {
    path: "/data",
    component: () => import("@/components/tabbar"),
    hidden: true,
    children: [
      {
        path: "/data/index",
        component: () => import("@/views/data/index"),
      },
    ],
  },
  {
    path: "/settings",
    component: () => import("@/components/tabbar"),
    hidden: true,
    children: [
      {
        path: "/settings/index",
        component: () => import("@/views/settings/index"),
      },
    ],
  },
  {
    name: "call",
    path: "/call",
    component: () => import("@/components/tabbar2"),
    hidden: true,
    redirect: "/call/callDataCountUser",
    children: [
      {
        meta: { title: "坐席绑定", icon: "home" },
        name: "callBind",
        path: "callBind",
        component: () => import("@/views/call/callBind"),
      },
      {
        meta: { title: "呼叫设置", icon: "home" },
        name: "callSetting",
        path: "callSetting",
        component: () => import("@/views/call/callSetting"),
      },
      {
        meta: { title: "呼叫日志", icon: "home" },
        name: "callBindLog",
        path: "callBindLog",
        component: () => import("@/views/call/callBingLog.vue"),
      },
      {
        meta: { title: "坐席监控", icon: "home" },
        name: "callMonitor",
        path: "callMonitor",
        component: () => import("@/views/call/callMonitor"),
      },
      {
        meta: { title: "状态时长统计报表", icon: "home" },
        name: "statusReport",
        path: "statusReport",
        component: () => import("@/views/call/statusReport"),
      },
      {
        meta: { title: "状态时长详情", icon: "home" },
        name: "statusReportDetail",
        path: "statusReportDetail",
        component: () => import("@/views/call/statusReportDetail"),
      },
      {
        meta: { title: "通话记录", icon: "home" },
        name: "callRecord",
        path: "callRecord",
        component: () => import("@/views/call/callRecord"),
      },
      {
        meta: { title: "话务量报表统计", icon: "home" },
        name: "callRecordCount",
        path: "callRecordCount",
        component: () => import("@/views/call/callRecordCount"),
      },
      {
        meta: { title: "呼入日话务高峰", icon: "home" },
        name: "callInPeakByDay",
        path: "callInPeakByDay",
        component: () => import("@/views/call/callInPeakByDay"),
      },
      {
        meta: { title: "呼入月话务高峰", icon: "home" },
        name: "callInPeakByMonth",
        path: "callInPeakByMonth",
        component: () => import("@/views/call/callInPeakByMonth"),
      },
      {
        meta: { title: "电话接通日志", icon: "home" },
        name: "callAnswerLog",
        path: "callAnswerLog",
        component: () => import("@/views/call/callAnswerLog"),
      },
      {
        meta: { title: "电话响铃日志", icon: "home" },
        name: "callRingLog",
        path: "callRingLog",
        component: () => import("@/views/call/callRingLog"),
      },
      {
        meta: { title: "电话结束日志", icon: "home" },
        name: "callEndLog",
        path: "callEndLog",
        component: () => import("@/views/call/callEndLog"),
      },
      {
        meta: { title: "数据概览", icon: "home" },
        name: "callDataCount",
        path: "callDataCount",
        component: () => import("@/views/call/callDataCount"),
      },
      {
        meta: { title: "个人数据概览", icon: "home" },
        name: "callDataCountUser",
        path: "callDataCountUser",
        component: () => import("@/views/call/callDataCountUser"),
      },
      {
        meta: { title: "历史数据统计", icon: "home" },
        name: "callHistory",
        path: "callHistory",
        component: () => import("@/views/call/callHistory"),
      },
      {
        meta: { title: "话务排班", icon: "home" },
        name: "callSchedule",
        path: "callSchedule",
        component: () => import("@/views/call/callSchedule"),
      },
    ],
  },
  { path: "*", redirect: "/404", hidden: true },
  // {
  //   path: "/importData",
  //   component: () => import("@/views/importData"),
  // },
];

// export const asyncRouterMap = [
//   {
//     path: "/goods",
//     component: () => import("@/components/tabbar"),
//     hidden: true,
//     children: [
//       {
//         path: "/goods/goods",
//         component: () => import("@/views/goods/goods")
//       }
//     ]
//   },
//   { path: "*", redirect: "/404", hidden: true }
// ];

export default new Router({
  mode: "history", //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});

// export default new Router({
//   // mode: 'history',
//   // mode:'hash',
//   routes: [
//     {
//       path: "/",
//       name: "login",
//       component: () => import("@/views/login/index"),
//       hidden: true
//     },
//     {
//       path: "/404",
//       component: () => import("@/components/404"),
//       hidden: true
//     },
//     {
//       path: "/index",
//       component: () => import("@/components/tabbar"),
//       redirect: "/index",
//       children: [
//         {
//           path: "/index",
//           component: () => import("@/views/home")
//         }
//       ]
//     },
//     {
//       path: "/system",
//       component: () => import("@/components/tabbar"),
//       hidden: true,
//       children: [
//         {
//           path: "/system/nav",
//           component: () => import("@/views/system/nav")
//         },
//         {
//           path: "/system/dept",
//           component: () => import("@/views/system/dept")
//         },
//         {
//           path: "/system/permission",
//           component: () => import("@/views/system/permission")
//         },
//         {
//           path: "/system/user",
//           component: () => import("@/views/system/user")
//         },
//         {
//           path: "/system/role",
//           component: () => import("@/views/system/role")
//         },
//         {
//           path: "/system/logs",
//           component: () => import("@/views/system/logs")
//         },
//         {
//           path: "/system/parameter",
//           component: () => import("@/views/system/parameter")
//         },
//         {
//           path: "/system/region",
//           component: () => import("@/views/system/region")
//         }
//       ]
//     },
//     {
//       path: "/goods",
//       component: () => import("@/components/tabbar"),
//       hidden: true,
//       children: [
//         {
//           path: "/goods/goods",
//           component: () => import("@/views/goods/goods")
//         }
//       ]
//     },
//     { path: "*", redirect: "/404", hidden: true }
//   ]
// });
