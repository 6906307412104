var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "manager-item" }, [
    _c("div", { staticClass: "item-head friend" }, [
      _c("span", { staticClass: "item-avatar" }, [
        _vm.item.avatar
          ? _c("img", { attrs: { src: _vm.item.avatar } })
          : _vm._e(),
      ]),
      _c("h4", [_vm._v(_vm._s(_vm.item.name))]),
      _c("span", [_vm._v(_vm._s(_vm.item.sign))]),
      _c("span", [
        _c("i", {
          staticClass: "m-icon-message",
          on: { click: _vm.handleBuildChat },
        }),
        _c("i", { staticClass: "m-icon-email" }),
        _vm._v(" "),
        _c("i", { staticClass: "m-icon-QQ" }),
      ]),
    ]),
    _c("div", { staticClass: "item-body friend" }, [
      _c("div", { staticClass: "body-item" }, [
        _c("p", [
          _c("em", [_vm._v(" IChat ")]),
          _c("span", [
            _vm._v(" " + _vm._s(_vm.item.cid) + " "),
            _c("i", { staticClass: "m-icon-edit" }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "body-item" }, [
        _c("p", [
          _c("em", [_vm._v(" 昵称 ")]),
          _c("span", [_vm._v(" " + _vm._s(_vm.item.name) + " ")]),
        ]),
      ]),
      _c("div", { staticClass: "body-item" }, [
        _c("p", [
          _c("em", [_vm._v(" 备注 ")]),
          _c("span", { staticClass: "remark" }, [
            _vm._v(" " + _vm._s(_vm.friendRemark) + " "),
            _c("i", { staticClass: "m-icon-edit" }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "body-item" }, [
        _c("p", [
          _c("em", [_vm._v(" 分组 ")]),
          _c("span", [_vm._v(" " + _vm._s(_vm.item.groupName) + " ")]),
        ]),
      ]),
      _c("div", { staticClass: "body-item" }, [
        _c("p", [
          _c("em", [_vm._v(" 手机 ")]),
          _c("span", [_vm._v(" " + _vm._s(_vm.item.iphone) + " ")]),
        ]),
      ]),
      _c("div", { staticClass: "body-item" }, [
        _c("p", [
          _c("em", [_vm._v(" 邮箱 ")]),
          _c("span", [_vm._v(" " + _vm._s(_vm.item.email) + " ")]),
        ]),
      ]),
      _c("div", { staticClass: "body-item" }, [
        _c("p", [
          _c("em", [_vm._v(" 说明 ")]),
          _c("span", [_vm._v(" " + _vm._s(_vm.friendIntroduce) + " ")]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }