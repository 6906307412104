import Vue from "vue";
import App from "./App";
import router from "./router";
import Cookies from "js-cookie";
import store from "./store";
import "@/permission";
// 引入iconfont图标
import "@/styles/iconfont/iconfont.css";
import "@/styles/iconfont/iconfont.js";
import "@/styles/iconfont/iconfont.json";
import "@/styles/iconfont/iconfont.ttf";
// 增加im组件
import MChat from "../src/packages/index";
// 引入Element-UI
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
//加载全局api前缀配置
import baseUrls from "@/api/baseUrls";
//加载全局axios,tools配置
import { ajax, tools } from "@/utils/tools";
// 引入自定义组件
import DcPointComponents from "dc-point-components";
import "dc-point-components/dist/dc-point-components.css";
// 引入高德地图组件
import VueAMap from "vue-amap";
// 引入公用的css
import "@/styles/commons.scss";
import "@/styles/index.scss"; // global css
// 引入vue-quill-editor富文本编辑器组件
import VueQuillEditor from "vue-quill-editor";
// 引入样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
// 全局引入echarts
import * as echarts from "echarts";

Vue.prototype.$echarts = echarts;
Vue.prototype.baseUrls = baseUrls;
Vue.prototype.topsAjax = ajax;
Vue.prototype.topsTools = tools;
Vue.prototype.dcAjax = ajax;
Vue.use(Element, {
  size: Cookies.get("size") || "medium", // set element-ui default size
});
Vue.use(MChat);
Vue.use(DcPointComponents);
Vue.use(VueQuillEditor);
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "75bd03753b8681e13029e405d87a00ba",
  plugin: ["AMap.Riding", "AMap.Autocomplete", "AMap.PlaceSearch", "AMap.Scale", "AMap.OverView", "AMap.ToolBar", "AMap.MapType", "AMap.PolyEditor", "AMap.CircleEditor", "AMap.Geolocation"],
  v: "2.0",
  uiVersion: "1.0",
});
// 高德API 安全秘钥设置
window._AMapSecurityConfig = {
  securityJsCode: "537950337e497b0d9343d0d4b033cb1f",
};
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  document.title = process.env.VUE_APP_TITLE;
  next();
});
if (process.env.DEPLOYED_AT && !localStorage.getItem("deployedAt")) {
  document.cookie = `Authorization=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  localStorage.setItem("deployedAt", process.env.DEPLOYED_AT);
}
new Vue({
  router,
  store,
  mounted() {
    // 监听form提交事件
    document.addEventListener("submit", this.preventFormSubmit);
  },
  beforeDestroy() {
    // 移除监听器
    document.removeEventListener("submit", this.preventFormSubmit);
  },
  methods: {
    preventFormSubmit(event) {
      // 检查是否是在你希望允许提交的场景之外触发的提交
      // 例如，你可能想要允许在特定的表单或者特定的输入元素中按下回车时提交
      if (!this.shouldAllowSubmit(event)) {
        event.preventDefault();
      }
    },
    shouldAllowSubmit(event) {
      // 在这里添加你的逻辑来决定是否允许提交
      // 例如，你可以检查提交事件的源元素或者表单的特定属性
      // 这里是一个简单的示例，检查是否有一个特定的属性设置为允许提交
      const form = event.target;
      return form.dataset.allowSubmit === "true";
    },
  },
  render: (h) => h(App),
}).$mount("#app");
