var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "manager-item" }, [
    _c("div", { staticClass: "item-head group" }, [
      _c("span", { staticClass: "item-avatar" }, [
        _vm.item.avatar
          ? _c("img", { attrs: { src: _vm.item.avatar } })
          : _vm._e(),
      ]),
      _c("h4", [_vm._v(_vm._s(_vm.item.name))]),
      _c("span", [_vm._v("群号: 1234567456")]),
      _c("span", [
        _c("i", {
          staticClass: "m-icon-message",
          on: { click: _vm.handleBuildChat },
        }),
        _c("i", { staticClass: "m-icon-chuangzuo" }),
      ]),
    ]),
    _c("div", { staticClass: "item-body group" }, [
      _c("div", { staticClass: "body-item" }, [
        _c("p", [
          _c("em", [_vm._v(" 群名称 ")]),
          _c("span", [_vm._v(" " + _vm._s(_vm.item.name) + " ")]),
        ]),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "divider divider--horizontal" }),
      _c("div", { staticClass: "body-item" }, [
        _c("div", { staticClass: "group_notify" }, [
          _c("p", [
            _c("em", [_vm._v(" 群公告 ")]),
            _c("span", [_vm._v(" " + _vm._s(_vm.groupNotify) + " ")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "divider divider--horizontal" }),
      _c("div", { staticClass: "body-item" }, [
        _c("p", [
          _c("em", [_vm._v(" 我的群名片 ")]),
          _c("span", [_vm._v(" " + _vm._s(_vm.item.mine) + " ")]),
        ]),
      ]),
      _c("div", { staticClass: "divider divider--horizontal" }),
      _c("div", { staticClass: "body-item" }, [
        _c("p", [
          _c("em", [_vm._v(" 成员 ")]),
          _c("span", [_vm._v(" (" + _vm._s(_vm.userCount) + "人) ")]),
        ]),
      ]),
      _c("div", { staticClass: "divider divider--horizontal" }),
      _c("div", { staticClass: "body-item" }, [
        _c("div", { staticClass: "group_notify" }, [
          _c("p", [
            _c("em", [_vm._v(" 群简介 ")]),
            _c("span", [_vm._v(" " + _vm._s(_vm.item.introduce) + " ")]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "body-item" }, [
      _c("p", [
        _c("em", [_vm._v(" 群聊备注 ")]),
        _c("span", [_vm._v(" 海喵突击队 ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }