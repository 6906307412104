var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drawer-notice-box", staticStyle: {} },
    [
      _c("P", { staticClass: "title" }, [_vm._v("群通知")]),
      _vm.hasNotice
        ? _c(
            "ul",
            _vm._l(_vm.items, function (item, k) {
              return _c(
                "li",
                {
                  key: k,
                  on: {
                    click: function ($event) {
                      return _vm.bindClick("notice", item)
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "【" + _vm._s(item.type) + "】" + _vm._s(item.title)
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _c("div", [
            _c("img", {
              staticClass: "notice-img",
              attrs: { src: _vm.unnoticedImg },
            }),
            _c("p", { staticClass: "unnoticed" }, [_vm._v("暂时没有新通知")]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }