var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "message-box-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "message-box__wrapper",
        attrs: {
          tabindex: "-1",
          role: "dialog",
          "aria-modal": "true",
          "aria-label": _vm.title || "dialog",
        },
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) return null
            return _vm.handleWrapperClick.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "message-box" }, [
          _vm.title !== null
            ? _c("div", { staticClass: "message-box_head" }, [
                _c("span", { staticClass: "message-box_title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c("div", { staticClass: "message-box_header_group" }, [
                  _vm.showClose
                    ? _c(
                        "button",
                        {
                          staticClass: "message-box_header_btn",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAction(
                                _vm.distinguishCancelAndClose
                                  ? "close"
                                  : "cancel"
                              )
                            },
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleAction(
                                _vm.distinguishCancelAndClose
                                  ? "close"
                                  : "cancel"
                              )
                            },
                          },
                        },
                        [_c("i", { staticClass: "m-icon-close" })]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "message-box_body" }, [
            _vm.message !== ""
              ? _c("div", { staticClass: "message-box_content" }, [
                  _vm.icon && !_vm.center && _vm.message !== ""
                    ? _c("div", { class: ["status", _vm.icon] })
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "message-box_message" },
                    [
                      _vm._t("default", function () {
                        return [
                          !_vm.dangerouslyUseHTMLString
                            ? _c("p", [_vm._v(" " + _vm._s(_vm.message))])
                            : _c("p", {
                                domProps: { innerHTML: _vm._s(_vm.message) },
                              }),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm.showInput
                    ? _c("div", { staticClass: "message-box_input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.inputValue,
                              expression: "inputValue",
                            },
                          ],
                          ref: "input",
                          attrs: { placeholder: _vm.inputPlaceholder },
                          domProps: { value: _vm.inputValue },
                          on: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handleInputEnter.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.inputValue = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "message-box__errormsg",
                            style: {
                              visibility: !!_vm.editorErrorMessage
                                ? "visible"
                                : "hidden",
                            },
                          },
                          [_vm._v(_vm._s(_vm.editorErrorMessage))]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "message-box_footer" }, [
            _c("span", { staticClass: "message-box-footer" }, [
              _vm.showCancelButton
                ? _c(
                    "button",
                    {
                      staticClass: "m-button",
                      attrs: {
                        type: "button",
                        loading: _vm.cancelButtonLoading,
                        round: _vm.roundButton,
                        "aria-label": "Close",
                      },
                      on: {
                        click: _vm.handleClose,
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleAction("cancel")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.cancelButtonText) + " ")]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showConfirmButton,
                      expression: "showConfirmButton",
                    },
                  ],
                  ref: "confirm",
                  staticClass: "m-button m-button-primary",
                  attrs: {
                    loading: _vm.confirmButtonLoading,
                    round: _vm.roundButton,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleAction("confirm")
                    },
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleAction("confirm")
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.confirmButtonText) + " ")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }