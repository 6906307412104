<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import baseUrls from "@/api/baseUrls";
import { EventBus } from "@/store/event-bus";

export default {
  name: "App",
  data() {
    return {
      socket: null,
      intervalId: "",
    };
  },
  created() {},
  destroyed() {},
  mounted() {
    let user = this.$store.state.user["user"];
    if (user !== null && user.userName) {
      user.username = user.userName
    }
    if (user !== null && user.username) {
      this.init();
    }
    let _this = this;
    this.intervalId = setInterval(function () {
      let user = _this.$store.state.user["user"];
      if (user !== null && user.username) {
        if (_this.socket == null || _this.socket.readyState == 3) {
          _this.init();
        } else {
          if(_this.socket.readyState === WebSocket.OPEN) {
            _this.socket.send("heart beat");
          } else {
              console.error('WebSocket is not open. ReadyState:', _this.socket.readyState);
              _this.init();
          }
          
        }
      }
    }, 10000);
  },

  methods: {
    // 初始化方法
    init() {
      // console.log("初始化");
      //1、websocket接口的url
      // let ws = "ws://192.168.110.209:9102/ws/platofrmAsync/" + this.$store.state.user["user"].username ? this.$store.state.user["user"].username : this.$store.state.user["user"].userName;
      let ws = `${baseUrls.ws}/platformAsync/` + (this.$store.state.user["user"].username ? this.$store.state.user["user"].username : this.$store.state.user["user"].userName);
      // 实例化socket
      this.socket = new WebSocket(ws);
      // 监听socket连接
      this.socket.onopen = this.socketopen;
      // 监听socket错误信息
      this.socket.onerror = this.error;
      // 监听socket消息
      this.socket.onmessage = this.getMessage;
      // 监听socket断开连接的消息
      this.socket.close = this.close;
    },

    // 连接成功方法
    socketopen() {
      console.log("socket连接成功");
    },
    // 连接错误
    error() {
      // console.log("连接错误");
    },
    // 接受信息接口
    getMessage(message) {
      console.log("收到消息");
      if (message.data === 'heart beat') {
        return
      }
      // console.log(message);
      //当接受到信息之后，就可以做后续的处理了
      // let data = JSON.parse(message.data);
      this.$notify({
        title: "消息通知",
        type: "success",
        message: message.data,
        position: "top-right",
      });
      EventBus.$emit("refreshPage");
    },
    // 关闭处理
    close() {
      console.log("连接关闭");
    },
  },
};
</script>

<style lang="scss">
.notifyButton {
  padding: 5px 30px;
  background-color: #3498db; /* 设置背景颜色为蓝色 */
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
}

.notifyTitle {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: rgb(245, 154, 35);
}

.notifyContent {
  margin-bottom: 10px;
}
</style>
