import { render, staticRenderFns } from "./index.vue?vue&type=template&id=048cdcc5"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\banling-web-front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('048cdcc5')) {
      api.createRecord('048cdcc5', component.options)
    } else {
      api.reload('048cdcc5', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=048cdcc5", function () {
      api.rerender('048cdcc5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/packages/main/src/page/chatPage/content/chat/drawer/index.vue"
export default component.exports