var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    [
      _c(
        "m-popover",
        {
          ref: "popover",
          attrs: { placement: "right", width: "100", trigger: "right" },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("ul", { staticClass: "item-menu-box" }, [
            _c("li", { on: { click: _vm.handleAddGroup } }, [
              _vm._v("添加分组"),
            ]),
            _c("li", { on: { click: _vm.handleRenameGroup } }, [
              _vm._v("分组重命名"),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "item_title",
              attrs: { slot: "reference" },
              on: {
                click: _vm.handleOpen,
                contextmenu: function ($event) {
                  $event.preventDefault()
                  return _vm.handleOpenMenu.apply(null, arguments)
                },
              },
              slot: "reference",
            },
            [
              _c("i", { class: _vm.icon }, [_vm._v("")]),
              _c("span", [_vm._v(_vm._s(_vm.title))]),
              _c("em", [_vm._v("(" + _vm._s(_vm.list.length) + ")")]),
            ]
          ),
        ]
      ),
      _c(
        "ul",
        { class: ["item_list", _vm.showClass] },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              class: _vm.activeClass(index),
              on: {
                click: function ($event) {
                  return _vm.bindItemClick(item, index)
                },
                dblclick: function ($event) {
                  return _vm.handleBuildChat(item)
                },
              },
            },
            [
              !item.avatar
                ? _c("div", { staticClass: "group_avatar" }, [
                    _c("i", { staticClass: "m-icon-qunzu" }),
                  ])
                : _c("img", {
                    class: { offline: _vm.type === "friend" && !item.online },
                    attrs: { src: item.avatar },
                  }),
              _c("span", [_vm._v(_vm._s(item.name))]),
              _c("p", { attrs: { title: item.sign } }, [
                _vm._v(_vm._s(item.sign)),
              ]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }