var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "drawer-user-list" }, [
    _c("ul", [
      _c("li", [
        _c("div", {
          staticClass: "list-title",
          domProps: { innerHTML: _vm._s(_vm.statusText) },
        }),
      ]),
      _c("li", [
        _c("div", { staticClass: "list-filter" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm._value,
                expression: "_value",
              },
            ],
            staticClass: "input-search",
            attrs: { placeholder: "搜索成员" },
            domProps: { value: _vm._value },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm._value = $event.target.value
              },
            },
          }),
          _vm._m(0),
        ]),
      ]),
    ]),
    _c(
      "ul",
      { staticClass: "list-box" },
      _vm._l(_vm.list, function (item, k) {
        return _c(
          "li",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: item.visible,
                expression: "item.visible",
              },
            ],
            key: k,
          },
          [
            _c("user-item", {
              attrs: { item: item },
              on: { click: _vm.bindClick },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "input-search-prefix" }, [
      _c("i", { staticClass: "im-icon m-icon-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }