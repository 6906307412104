import { getToken, setToken, removeToken } from "@/utils/auth";
import Vue from "vue";

const user = {
  state: {
    user: JSON.parse(sessionStorage.getItem("userInfo")),
    token: getToken(),
    name: "",
    avatar: "",
    roles: [],
    navs: JSON.parse(sessionStorage.getItem("nav")),
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USERINFO: (state, user) => {
      state.user = user;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_NAVS: (state, navs) => {
      state.navs = navs;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      let url = `/api/auth/login`;
      return new Promise((resolve, reject) => {
        Vue.prototype.topsAjax
          .post(url, userInfo)
          .then((response) => {
            const tokenStr = response.access_token;
            const user = response.userInfo;
            const nav = response.nav.filter((p) => p.type === "1");
            const userInfo = response.userInfo.sysUser;
            setToken(tokenStr);
            commit("SET_TOKEN", tokenStr);
            commit("SET_USERINFO", user);
            commit("SET_NAVS", nav);
            sessionStorage.setItem("nav", JSON.stringify(nav));
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {});
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            removeToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    },
  },
};

export default user;
