var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "chat-toolbar" }, [
    _c("div", { staticClass: "chat-tools" }, [
      _c(
        "span",
        { staticClass: "chat-tool-item", attrs: { title: "发送表情" } },
        [
          _c(
            "m-popover",
            {
              ref: "popover",
              attrs: { placement: "top-start", trigger: "click" },
            },
            [
              _c("div", { staticClass: "emoji-box" }, [
                _c(
                  "ul",
                  _vm._l(_vm.emojis, function (item, key) {
                    return _c(
                      "li",
                      {
                        key: item,
                        on: {
                          click: function ($event) {
                            return _vm.emojiEmit(key)
                          },
                        },
                      },
                      [_c("img", { attrs: { src: item } })]
                    )
                  }),
                  0
                ),
              ]),
              _c("i", {
                staticClass: "m-icon-smile",
                attrs: { slot: "reference" },
                slot: "reference",
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "chat-tool-item", attrs: { title: "发送图片" } },
        [
          _c("i", { staticClass: "m-icon-picture" }),
          _c("input", {
            ref: "uploadImage",
            attrs: { type: "file" },
            on: { change: _vm.uploadChange },
          }),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }