var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("notice-box", {
        attrs: { items: _vm.notices },
        on: { click: _vm.bindClick },
      }),
      _c("user-list", {
        attrs: { list: _vm._list },
        on: { click: _vm.bindClick },
        model: {
          value: _vm.filter,
          callback: function ($$v) {
            _vm.filter = $$v
          },
          expression: "filter",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }