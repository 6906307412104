var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ChatMain", {
    ref: "chat",
    attrs: {
      mine: _vm.mine,
      friends: _vm.friends,
      groups: _vm.groups,
      chats: _vm.channel,
      config: _vm.config,
    },
    on: { bindEvent: _vm.handleEvent },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }