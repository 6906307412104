var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: { name: "dialog-fade" },
      on: { "after-enter": _vm.afterEnter, "after-leave": _vm.afterLeave },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          class: { dialog__wrapper: _vm.wrapper },
        },
        [
          _c(
            "div",
            {
              key: _vm.key,
              ref: "dialog",
              class: [
                "dialog",
                { dialog__center: _vm.center },
                _vm.customClass,
              ],
              attrs: { role: "dialog" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "dialog_head",
                  staticStyle: { cursor: "move" },
                  on: { mousedown: _vm.handPageDrag },
                },
                [
                  _vm._t("title", function () {
                    return [
                      _c("span", { staticClass: "dialog_title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                    ]
                  }),
                  _c("div", { staticClass: "dialog_header_group" }, [
                    _vm.shrink
                      ? _c(
                          "button",
                          {
                            staticClass: "dialog_header_btn",
                            attrs: { type: "button", "aria-label": "shrink" },
                            on: { click: _vm.handleShrink },
                          },
                          [_c("i", { class: _vm.iconMaxClass })]
                        )
                      : _vm._e(),
                    _vm.showClose
                      ? _c(
                          "button",
                          {
                            staticClass: "dialog_header_btn",
                            attrs: { type: "button", "aria-label": "Close" },
                            on: { click: _vm.handleClose },
                          },
                          [_c("i", { staticClass: "m-icon-close" })]
                        )
                      : _vm._e(),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShrink,
                      expression: "isShrink",
                    },
                  ],
                  staticClass: "dialog_body dialog_content",
                },
                [_vm._t("default")],
                2
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShrink,
                      expression: "isShrink",
                    },
                  ],
                  staticClass: "dialog_foote",
                },
                [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [_vm._t("footer")],
                    2
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }