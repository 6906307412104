var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "main-box-content" }, [
    _c(
      "div",
      { staticClass: "manager-page" },
      [
        _c("div", { staticClass: "manager-box" }, [
          _vm._m(0),
          _c(
            "ul",
            { staticClass: "manage-tab-content" },
            [
              _c("tab-item", {
                attrs: {
                  type: "group",
                  title: "群组",
                  selected: _vm.selected,
                  list: _vm.groups,
                },
                on: {
                  itemClick: _vm.handleItemClick,
                  bindEvent: _vm.handleEvent,
                },
              }),
              _c("span", { staticClass: "tab-divider" }, [_vm._v("好友")]),
              _vm._l(_vm.friends, function (item, index) {
                return _c("tab-item", {
                  key: index,
                  attrs: {
                    type: "friend",
                    title: item.name,
                    selected: _vm.selected,
                    id: item.id,
                    list: item.userList,
                  },
                  on: {
                    itemClick: _vm.handleItemClick,
                    bindEvent: _vm.handleEvent,
                  },
                })
              }),
            ],
            2
          ),
        ]),
        _c("manage-content", {
          attrs: { current: _vm.current },
          on: { bindEvent: _vm.handleEvent },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "search-box" }, [
      _c("span", [_vm._v("加好友")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }