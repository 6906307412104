var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "transition",
        {
          attrs: { name: _vm.transition },
          on: {
            "after-enter": _vm.handleAfterEnter,
            "after-leave": _vm.handleAfterLeave,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.disabled && _vm.showPopper,
                  expression: "!disabled && showPopper",
                },
              ],
              ref: "popper",
              staticClass: "m-popover m-popper",
              class: [_vm.popperClass, _vm.content && "m-popover--plain"],
              style: { width: _vm.width + "px" },
              attrs: {
                role: "tooltip",
                id: _vm.tooltipId,
                "aria-hidden":
                  _vm.disabled || !_vm.showPopper ? "true" : "false",
              },
            },
            [
              _vm.title
                ? _c("div", {
                    staticClass: "m-popover__title",
                    domProps: { textContent: _vm._s(_vm.title) },
                  })
                : _vm._e(),
              _vm._t("default", function () {
                return [_vm._v(_vm._s(_vm.content))]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._t("reference"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }