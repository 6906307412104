var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "im-user-item",
      on: {
        dblclick: function ($event) {
          return _vm.bindClick({ key: "user", value: _vm.item })
        },
      },
    },
    [
      _c("img", {
        class: ["im-user-avatar", _vm.item.online ? "" : "offline"],
        style: _vm.coverSize,
        attrs: { src: _vm.avatar },
      }),
      _c("div", { staticClass: "im-user-info" }, [
        _c("p", [_vm._v(_vm._s(_vm.item.name))]),
      ]),
      _c("div", { staticClass: "im-user-role" }, [
        _vm.item.role
          ? _c("i", { class: ["m-icon-user", _vm.item.role] })
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }